import { BASE_URL_DASHBOARD } from "../utils/constants";

export const addPost = async (formData, calendarUuid) => {
  try {
    const url = `${BASE_URL_DASHBOARD}/post`;

    formData.append("calendar_uuid", calendarUuid);
    

    const response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include",
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.log("error message", errorData);

      throw new Error(errorData.errors.message || "Failed to add post");
    }

    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Add post error:", error);
    throw error;
  }
};

export const getPosts = async (calendarUuid) => {
  try {
    const url = `${BASE_URL_DASHBOARD}/post?calendar_uuid=${calendarUuid}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    const data = await response.json();

    if (response.ok) {
      return data.data;
    } else {
      throw new Error(data.message || "Failed to fetch posts");
    }
  } catch (error) {
    console.warn("Get posts error:", error.message);
    throw error;
  }
};

export const updatePost = async (formData, calendarUUID, postUUID) => {
  try {
    const url = `${BASE_URL_DASHBOARD}/post`;
    formData.append("calendar_uuid", calendarUUID);
    formData.append("post_uuid", postUUID);

    const response = await fetch(url, {
      method: "PUT",
      body: formData,
      credentials: "include",
    });

    const data = await response.json();

    if (response.ok && data.success) {
      return data.data;
    } else {
      throw new Error(data.message || "Failed to update post");
    }
  } catch (error) {
    console.warn("Update post error:", error.message);
    throw error;
  }
};

export const deletePost = async (calendarUUID, postUUID) => {
  try {
    const url = `${BASE_URL_DASHBOARD}/post/${postUUID}`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ calendar_uuid: calendarUUID }),
      credentials: "include",
    });

    const data = await response.json();

    if (response.ok && data.success) {
      return data.data;
    } else {
      throw new Error(data.message || "Failed to delete post");
    }
  } catch (error) {
    console.warn("Delete post error:", error.message);
    throw error;
  }
};

export const addComment = async (postUUID, content) => {
  try {
    const url = `${BASE_URL_DASHBOARD}/post/comment`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        post_uuid: postUUID,
        content,
      }),
      credentials: "include",
    });

    const data = await response.json();

    if (response.ok && data.success) {
      return data.data;
    } else {
      throw new Error(data.message || "Failed to add comment");
    }
  } catch (error) {
    console.error("Add comment error:", error);
    throw error;
  }
};

/* Sample request:
{
  "post_uuid": "123e4567-e89b-12d3-a456-426614174000",
  "content": "This is a great post!"
}

Sample response:
{
  "success": true,
  "data": {
    "id": 1,
    "uuid": "123e4567-e89b-12d3-a456-426614174001",
    "post_uuid": "123e4567-e89b-12d3-a456-426614174000",
    "calendar_user_uuid": "123e4567-e89b-12d3-a456-426614174002",
    "content": "This is a great post!",
    "created_at": "2023-04-20T10:30:00Z",
    "updated_at": "2023-04-20T10:30:00Z"
  }
}
*/

export const getComments = async (postUUID) => {
  try {
    const url = `${BASE_URL_DASHBOARD}/post/comments?post_uuid=${postUUID}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    const data = await response.json();

    if (response.ok && data.success) {
      return data.data;
    } else {
      throw new Error(data.message || "Failed to fetch comments");
    }
  } catch (error) {
    console.warn("Get comments error:", error.message);
    throw error;
  }
};

/* Sample request:
GET /post/comments?post_uuid=123e4567-e89b-12d3-a456-426614174000

Sample response:
{
  "success": true,
  "data": [
    {
      "id": 1,
      "uuid": "123e4567-e89b-12d3-a456-426614174001",
      "post_uuid": "123e4567-e89b-12d3-a456-426614174000",
      "calendar_user_uuid": "123e4567-e89b-12d3-a456-426614174002",
      "content": "This is a great post!",
      "created_at": "2023-04-20T10:30:00Z",
      "updated_at": "2023-04-20T10:30:00Z",
      "commenter_name": "John Doe",
      "commenter_image_url": "https://example.com/profile.jpg",
      "calendar_role": "admin"
    },
    {
      "id": 2,
      "uuid": "123e4567-e89b-12d3-a456-426614174003",
      "post_uuid": "123e4567-e89b-12d3-a456-426614174000",
      "calendar_user_uuid": "123e4567-e89b-12d3-a456-426614174004",
      "content": "I agree, very informative!",
      "created_at": "2023-04-20T11:00:00Z",
      "updated_at": "2023-04-20T11:00:00Z",
      "commenter_name": "Jane Smith",
      "commenter_image_url": "https://example.com/jane.jpg",
      "calendar_role": "editor"
    }
  ]
}
*/

export const updateComment = async (commentUUID, content) => {
  try {
    const url = `${BASE_URL_DASHBOARD}/post/comment`;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ comment_uuid: commentUUID, content }),
      credentials: "include",
    });

    const data = await response.json();

    if (response.ok && data.success) {
      return data.data;
    } else {
      throw new Error(data.message || "Failed to update comment");
    }
  } catch (error) {
    console.warn("Update comment error:", error.message);
    throw error;
  }
};

/* Sample request:
{
  "comment_uuid": "123e4567-e89b-12d3-a456-426614174001",
  "content": "This is an updated comment!"
}

Sample response:
{
  "success": true,
  "data": {
    "id": 1,
    "uuid": "123e4567-e89b-12d3-a456-426614174001",
    "post_uuid": "123e4567-e89b-12d3-a456-426614174000",
    "calendar_user_uuid": "123e4567-e89b-12d3-a456-426614174002",
    "content": "This is an updated comment!",
    "created_at": "2023-04-20T10:30:00Z",
    "updated_at": "2023-04-20T12:00:00Z"
  }
}
*/

export const deleteComment = async (commentUUID) => {
  try {
    const url = `${BASE_URL_DASHBOARD}/post/comment?comment_uuid=${commentUUID}`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    const data = await response.json();

    if (response.ok && data.success) {
      return data.data;
    } else {
      throw new Error(data.message || "Failed to delete comment");
    }
  } catch (error) {
    console.warn("Delete comment error:", error.message);
    throw error;
  }
};

/* Sample request:
DELETE /post/comment?comment_uuid=123e4567-e89b-12d3-a456-426614174001

Sample response:
{
  "success": true,
  "data": "Comment deleted successfully"
}
*/
