import PropTypes from "prop-types";
import { useEffect } from "react";
import { useAuth } from "src/hooks/use-auth";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { LoadingScreen } from "src/sections/loading-screen";

export const GuestGuard = ({ children }) => {
  const { user, loading } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (!loading) {
      if (user) {
        router.replace(paths.dashboard.index);
      }
    }
  }, [user, loading, router]);

  if (loading) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node.isRequired,
};
