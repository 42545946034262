import { MenuItem, Popover } from "@mui/material";

export const TenantPopover = (props) => {
  const {
    anchorEl,
    onChange,
    onClose,
    open = false,
    organizations,
    ...other
  } = props;

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
      disableScrollLock
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      keepMounted
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 250 } }}
      {...other}
    >
      {organizations.map((org) => (
        <MenuItem key={org.uuid} onClick={() => onChange(org)}>
          {org.name}
        </MenuItem>
      ))}
    </Popover>
  );
};
