import PropTypes from "prop-types";
import { withAuthGuard } from "src/hocs/with-auth-guard";
import { useSections } from "./config";
import { VerticalLayout } from "./vertical-layout";
import { settings } from "src/utils/constants";
import { useOrganization } from "src/contexts/organization-context";

import { Button, Tooltip } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AddIcon from "@mui/icons-material/Add";
import { paths } from "src/paths";
import { useNavigate } from "react-router-dom";

export const Layout = withAuthGuard((props) => {
  const navigate = useNavigate();
  const { currentOrganization, setAddCalendarFromNavigation } =
    useOrganization();
  const sections = useSections();
  const handleNavButtonClick = () => {
    if (currentOrganization.role === "admin") {
      // Set the addCalendarFromNavigation to true when button is clicked
      setAddCalendarFromNavigation(true);
      navigate(`${paths.dashboard.workspace}?org=${currentOrganization.uuid}`);
    }
  };

  const CalendarAddButton = ({ onClick }) => {
    const isDisabled = currentOrganization.role !== "admin"; // Disable if not admin
    const disabledMessage =
      "You do not have permission to add a calendar. Please contact your administrator.";

    return (
      <Tooltip
        title={isDisabled ? disabledMessage : ""}
        arrow
        placement="bottom"
      >
        <span>
          <Button
            variant="contained"
            startIcon={<CalendarTodayIcon />}
            endIcon={<AddIcon />}
            onClick={onClick}
            fullWidth
            disabled={isDisabled} // Disable button if not admin
            sx={{
              justifyContent: "flex-start",
              textTransform: "none",
              fontWeight: "bold",
              "&.Mui-disabled": {
                backgroundColor: "#e0e0e0",
                color: "#9e9e9e",
              },
              "&:hover": {
                backgroundColor: isDisabled ? "#e0e0e0" : "primary.dark",
              },
            }}
          >
            Add Calendar
          </Button>
        </span>
      </Tooltip>
    );
  };

  CalendarAddButton.propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  const navButton = {
    component: CalendarAddButton,
    props: { onClick: handleNavButtonClick },
  };

  return (
    <VerticalLayout
      sections={sections}
      navColor={settings.navColor}
      navButton={navButton}
      {...props}
    />
  );
});

Layout.propTypes = {
  children: PropTypes.node,
};
