import { BASE_URL_DASHBOARD, BASE_URL_API } from "../utils/constants";

class AuthApi {
  async signUp(data) {
    const url = `${BASE_URL_DASHBOARD}/signup`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    data = await response.json();

    if (!data.success) {
      throw new Error(data.errors[0]);
    }

    return data;
  }

  async signIn({ email, password }) {
    const url = `${BASE_URL_DASHBOARD}/login`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Login failed");
    }

    return this.me();
  }

  async signOut() {
    const url = `${BASE_URL_DASHBOARD}/logout`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Logout failed");
    }
  }

  async me() {
    const url = `${BASE_URL_API}/sessions/whoami`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      return null;
    }

    return response.json();
  }

  async verifyAccount(token) {
    const url = `${BASE_URL_DASHBOARD}/verify_account`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ verification_token: token }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Account verification failed");
    }

    return response.json();
  }

  async resetPassword(email) {
    const url = `${BASE_URL_DASHBOARD}/reset_password`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error("Reset password failed");
    }
    return data;
  }

  async verifyPassword(token, password) {
    const url = `${BASE_URL_DASHBOARD}/verify_password`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token, new_password: password }),
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(" Password verify failed");
    }
    return data;
  }

  async googleLogin() {
    const url = `${BASE_URL_API}/channels/google/login`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error("Google Login Failed");
    }
    return data;
  }
}

export const authApi = new AuthApi();
