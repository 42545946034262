import { useCallback } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { Box, Button, Divider, Popover, Typography } from "@mui/material";
import { useAuth } from "src/hooks/use-auth";
import { paths } from "src/paths";
import { useNavigate } from "react-router-dom";
import { useRouter } from "src/hooks/use-router";
import { useOrganization } from "src/contexts/organization-context";

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const navigate = useNavigate();
  const { signOut, user } = useAuth();
  const router = useRouter();
  const { clearOrganizationState } = useOrganization();

  const handleLogout = useCallback(async () => {
    try {
      onClose?.();
      await signOut();
      clearOrganizationState();
      router.push(paths.index);
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong!");
    }
  }, [signOut, router, onClose, clearOrganizationState]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 200 } }}
      {...other}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="body1">{user?.name}</Typography>
        <Typography color="text.secondary" variant="caption">
          {user?.email}
        </Typography>
      </Box>
      <Divider sx={{ my: "0 !important" }} />
      <Box
        sx={{
          display: "flex",
          p: 1,
          justifyContent: "center",
        }}
      >
        <Button color="inherit" onClick={handleLogout} size="small">
          Logout
        </Button>
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
