import { useEffect } from "react";
import PropTypes from "prop-types";
import { useAuth } from "src/hooks/use-auth";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { LoadingScreen } from "src/sections/loading-screen";

export const AuthGuard = ({ children }) => {
  const router = useRouter();
  const { user, loading } = useAuth();

  useEffect(() => {
    if (!loading) {
      if (!user) {
        const searchParams = new URLSearchParams({
          returnTo: window.location.pathname,
        }).toString();
        const href = `${paths.auth.login}?${searchParams}`;
        router.replace(href);
      }
    }
  }, [user, loading, router]);

  if (loading) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
};
