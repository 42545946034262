import { Avatar, Box, ButtonBase, SvgIcon } from "@mui/material";
import { useMockedUser } from "src/hooks/use-mocked-user";
import { usePopover } from "src/hooks/use-popover";
import { AccountPopover } from "./account-popover";
import { useAuth } from "src/hooks/use-auth";

export const AccountButton = () => {
  const { user } = useAuth();
  const popover = usePopover();

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <Avatar
          src={user?.image_url}
          sx={{
            height: 32,
            width: 32,
            bgcolor: "primary.main",
            color: "primary.contrastText",
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          {`${user?.first_name.charAt(0)}${user?.last_name.charAt(0)}`}
        </Avatar>
      </Box>
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
