import { createContext, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { authApi } from "src/api/auth";

const STORAGE_KEY = "userDetails";

const initialState = {
  user: null,
  loading: true, // Added loading state
};

export const AuthContext = createContext({
  ...initialState,
  signIn: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  updateUser: () => {},
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Initialize loading state

  const updateUser = useCallback((newUser) => {
    setUser(newUser);
    if (newUser) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify({ user: newUser }));
    } else {
      localStorage.removeItem(STORAGE_KEY);
    }
  }, []);

  const initialize = useCallback(async () => {
    try {
      const response = await authApi.me();
      if (response && response.success) {
        updateUser(response.data.user);
      } else {
        updateUser(null);
      }
    } catch (err) {
      console.error(err);
      updateUser(null);
    } finally {
      setLoading(false);
    }
  }, [updateUser]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const signIn = useCallback(
    async (email, password) => {
      try {
        const response = await authApi.signIn({ email, password });
        if (response.success) {
          updateUser(response.data.user);
        }
        return response;
      } catch (error) {
        throw error;
      }
    },
    [updateUser]
  );

  const signUp = useCallback(
    async (email, firstName, lastName, password) => {
      try {
        const response = await authApi.signUp({
          email,
          password,
          first_name: firstName,
          last_name: lastName,
        });
        if (response && response.success) {
          return response.data.message;
        } else {
          throw new Error(response.data.message || "Sign up failed");
        }
      } finally {
        updateUser(null);
      }
    },
    [updateUser]
  );

  const signOut = useCallback(async () => {
    try {
      await authApi.signOut();
      updateUser(null);
    } catch (error) {
      console.error("Sign out error:", error);
      updateUser(null);
    }
  }, [updateUser]);

  const verifyAccount = useCallback(
    async (token) => {
      try {
        const response = await authApi.verifyAccount(token);
        if (response && response.success) {
          updateUser(response.data.user);
        } else {
          throw new Error(response.data.message || "Verification failed");
        }
      } catch (error) {
        updateUser(null);
        throw error;
      }
    },
    [updateUser]
  );

  const resetPassword = useCallback(async (email) => {
    const response = await authApi.resetPassword(email);
    if (response && response.success) {
      return response;
    } else {
      throw new Error(response.data.message || "Reset password failed");
    }
  }, []);

  const verifyPassword = useCallback(async (token, password) => {
    const response = await authApi.verifyPassword(token, password);
    if (response && response.success) {
      return response;
    } else {
      throw new Error(response.data.message || "Verify password failed");
    }
  }, []);

  const googleLogin = useCallback(async () => {
    const response = await authApi.googleLogin();
    if (response && response.success) {
      return response?.data;
    } else {
      throw new Error(response.data.message || "Google Login failed");
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        loading, // Provide loading state
        signIn,
        signUp,
        signOut,
        verifyAccount,
        updateUser,
        resetPassword,
        verifyPassword,
        googleLogin,
        initialize,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Export the Consumer
export const AuthConsumer = AuthContext.Consumer;
