import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  Stack,
  Avatar,
  Button,
  Box,
  Tooltip,
  Typography,
  Modal,
  IconButton,
  Card,
  CardContent,
  MobileStepper,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Scrollbar } from "src/components/scrollbar";
import { usePathname } from "src/hooks/use-pathname";
import { TenantSwitch } from "../tenant-switch";
import { SideNavSection } from "./side-nav-section";
import { useOrganization } from "src/contexts/organization-context";
import { useRouter } from "src/hooks/use-router";
import { useAuth } from "src/hooks/use-auth";
import { User, Briefcase, Mail } from "lucide-react";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";

import { useNavigate } from "react-router-dom";
import { paths } from "src/paths";
import { getInitials } from "src/utils/get-initials";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const SIDE_NAV_WIDTH = 280;

const useCssVars = (color) => {
  const theme = useTheme();

  return useMemo(() => {
    switch (color) {
      case "blend-in":
        if (theme.palette.mode === "dark") {
          return {
            "--nav-bg": theme.palette.background.default,
            "--nav-color": theme.palette.neutral[100],
            "--nav-border-color": theme.palette.neutral[700],
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[600],
            "--nav-item-icon-color": theme.palette.neutral[500],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[700],
            "--nav-item-chevron-color": theme.palette.neutral[700],
            "--nav-scrollbar-color": theme.palette.neutral[400],
          };
        } else {
          return {
            "--nav-bg": theme.palette.background.default,
            "--nav-color": theme.palette.text.primary,
            "--nav-border-color": theme.palette.neutral[100],
            "--nav-logo-border": theme.palette.neutral[100],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.text.secondary,
            "--nav-item-hover-bg": theme.palette.action.hover,
            "--nav-item-active-bg": theme.palette.action.selected,
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[400],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[400],
            "--nav-item-chevron-color": theme.palette.neutral[400],
            "--nav-scrollbar-color": theme.palette.neutral[900],
          };
        }

      case "discreet":
        if (theme.palette.mode === "dark") {
          return {
            "--nav-bg": theme.palette.neutral[900],
            "--nav-color": theme.palette.neutral[100],
            "--nav-border-color": theme.palette.neutral[700],
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[600],
            "--nav-item-icon-color": theme.palette.neutral[500],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[700],
            "--nav-item-chevron-color": theme.palette.neutral[700],
            "--nav-scrollbar-color": theme.palette.neutral[400],
          };
        } else {
          return {
            "--nav-bg": theme.palette.neutral[50],
            "--nav-color": theme.palette.text.primary,
            "--nav-border-color": theme.palette.divider,
            "--nav-logo-border": theme.palette.neutral[200],
            "--nav-section-title-color": theme.palette.neutral[500],
            "--nav-item-color": theme.palette.neutral[500],
            "--nav-item-hover-bg": theme.palette.action.hover,
            "--nav-item-active-bg": theme.palette.action.selected,
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[400],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[400],
            "--nav-item-chevron-color": theme.palette.neutral[400],
            "--nav-scrollbar-color": theme.palette.neutral[900],
          };
        }

      case "evident":
        if (theme.palette.mode === "dark") {
          return {
            "--nav-bg": theme.palette.neutral[800],
            "--nav-color": theme.palette.common.white,
            "--nav-border-color": "transparent",
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.common.white,
            "--nav-item-disabled-color": theme.palette.neutral[500],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[500],
            "--nav-item-chevron-color": theme.palette.neutral[600],
            "--nav-scrollbar-color": theme.palette.neutral[400],
          };
        } else {
          return {
            "--nav-bg": theme.palette.neutral[800],
            "--nav-color": theme.palette.common.white,
            "--nav-border-color": "transparent",
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.common.white,
            "--nav-item-disabled-color": theme.palette.neutral[500],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[500],
            "--nav-item-chevron-color": theme.palette.neutral[600],
            "--nav-scrollbar-color": theme.palette.neutral[400],
          };
        }

      default:
        return {};
    }
  }, [theme, color]);
};

export const SideNav = (props) => {
  const { user } = useAuth();
  const theme = useTheme();

  const { setSelectedCalendarSettings } = useOrganization();
  const navigate = useNavigate();
  const { color = "evident", sections = [], navButton } = props;
  const pathname = usePathname();
  const cssVars = useCssVars(color);
  const {
    calendars,
    selectedCalendar,
    handleSelectCalendar,
    agentView,
    setAgentView,
  } = useOrganization();
  const router = useRouter();

  useEffect(() => {
    return () => {
      setAgentView(false); // Set agent view to false on unmount
    };
  }, [setAgentView]); // Add setAgentView to the dependency array

  const [openDemo, setOpenDemo] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const tips = [
    {
      title: "Quick Calendar Setup",
      content: "Learn how to set up your calendar in just a few clicks",
    },
    {
      title: "Efficient Scheduling",
      content: "Discover our powerful scheduling features",
    },
    {
      title: "Team Collaboration",
      content: "See how to collaborate effectively with your team",
    },
  ];

  const maxSteps = tips.length;

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const updatedSections = useMemo(() => {
    return sections.map((section) => {
      if (section.title === "Calendar") {
        return {
          ...section,
          items: calendars
            .filter((calendar) => calendar.type !== "agent")
            .map((calendar) => ({
              title: calendar.username,
              icon: (
                <Avatar src={calendar.image_url} sx={{ width: 24, height: 24 }}>
                  {calendar.image_url ? null : getInitials(calendar.username)}
                </Avatar>
              ),
              onClick: () => {
                handleSelectCalendar(calendar);
                setAgentView(false);
                router.push(paths.dashboard.calendar);
              },
              active: selectedCalendar?.uuid === calendar.uuid,
            })),
        };
      }
      return section;
    });
  }, [
    sections,
    calendars,
    selectedCalendar,
    handleSelectCalendar,
    pathname,
    router,
  ]);

  if (!user) {
    return null;
  }

  const { email } = user;
  const isTruncated = email.length > 20;

  return (
    <Drawer
      anchor="left"
      open
      PaperProps={{
        sx: {
          ...cssVars,
          backgroundColor: "var(--nav-bg)",
          borderRightColor: "var(--nav-border-color)",
          borderRightStyle: "solid",
          borderRightWidth: 1,
          color: "var(--nav-color)",
          width: SIDE_NAV_WIDTH,
        },
      }}
      variant="permanent"
    >
      <Scrollbar
        sx={{
          height: "100%",
          "& .simplebar-content": {
            height: "100%",
            display: "flex",
            flexDirection: "column",
          },
          "& .simplebar-scrollbar:before": {
            background: "var(--nav-scrollbar-color)",
          },
        }}
      >
        <Stack
          sx={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <Stack alignItems="center" direction="row" spacing={2} sx={{ p: 3 }}>
            <TenantSwitch sx={{ flexGrow: 1 }} />
          </Stack>
          <Accordion
            defaultExpanded
            elevation={0}
            sx={{
              margin: "8px 16px !important",
              backgroundColor: "transparent",
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ color: "var(--nav-item-color)" }} />
              }
              sx={{
                color: "var(--nav-item-color)",
                padding: "0 8px",
                minHeight: "48px",
                "& .MuiAccordionSummary-content": {
                  margin: "0",
                },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CalendarTodayIcon sx={{ mr: 1 }} />
                <Typography>All Calendars</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <Box
                sx={{
                  maxHeight: "300px",
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    width: "4px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "var(--nav-scrollbar-color)",
                    borderRadius: "2px",
                  },
                }}
              >
                <Stack component="nav" spacing={1}>
                  {updatedSections.map((section, index) => (
                    <SideNavSection
                      items={section.items}
                      key={index}
                      pathname={pathname}
                      subheader={section.subheader}
                    />
                  ))}
                </Stack>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Stack
            sx={{
              p: 2,
            }}
          >
            {calendars.length !== 0 && navButton && (
              <div>
                <navButton.component {...navButton.props} />
              </div>
            )}
          </Stack>

          <Stack
            sx={{
              mt: "auto",
              borderTop: "1px solid",
              borderColor: "var(--nav-border-color)",
              pt: 2,
              pb: 2,
              px: 2,
            }}
          >
            <Button
              startIcon={<Briefcase size={20} />}
              sx={{
                justifyContent: "flex-start",
                color: "var(--nav-item-color)",
                py: 1,
                "&:hover": {
                  backgroundColor: "var(--nav-item-hover-bg)",
                },
              }}
              onClick={() => {
                setSelectedCalendarSettings(false);
                setAgentView(true);
                navigate(paths.dashboard.agents);
              }}
            >
              Agents
            </Button>
            <Button
              startIcon={<PlayCircleIcon />}
              sx={{
                justifyContent: "flex-start",
                color: "var(--nav-item-color)",
                py: 1,
                "&:hover": {
                  backgroundColor: "var(--nav-item-hover-bg)",
                },
              }}
              onClick={() => {
                setOpenDemo(true);
                setAgentView(false);
              }}
            >
              Watch Demo
            </Button>
            <Button
              startIcon={<Briefcase size={20} />}
              sx={{
                justifyContent: "flex-start",
                color: "var(--nav-item-color)",
                py: 1,
                "&:hover": {
                  backgroundColor: "var(--nav-item-hover-bg)",
                },
              }}
              onClick={() => {
                setSelectedCalendarSettings(false);
                navigate(paths.dashboard.workspaces);
                setAgentView(false);
              }}
            >
              Workspace
            </Button>
            <Button
              startIcon={<User size={20} />}
              sx={{
                justifyContent: "flex-start",
                color: "var(--nav-item-color)",
                py: 1,
                "&:hover": {
                  backgroundColor: "var(--nav-item-hover-bg)",
                },
              }}
              onClick={() => {
                navigate(paths.dashboard.account);
                setAgentView(false);
              }}
            >
              Profile
            </Button>

            <Tooltip
              title={isTruncated ? email : ""}
              arrow
              placement="right"
              disableHoverListener={!isTruncated}
            >
              <Button
                startIcon={<Mail size={20} />}
                sx={{
                  justifyContent: "flex-start",
                  color: "var(--nav-item-color)",
                  py: 1,
                  "&:hover": {
                    backgroundColor: "var(--nav-item-hover-bg)",
                  },
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  {email}
                </Typography>
              </Button>
            </Tooltip>
          </Stack>

          <Modal
            open={openDemo}
            onClose={() => setOpenDemo(false)}
            aria-labelledby="demo-modal"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: "90%", sm: 800 },
                bgcolor: "background.paper",
                borderRadius: 2,
                boxShadow: 24,
                outline: "none",
              }}
            >
              {/* Header */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  p: 2,
                  borderBottom: 1,
                  borderColor: "divider",
                }}
              >
                <Typography variant="h6" component="h2">
                  Product Demo & Tips
                </Typography>
                <IconButton
                  onClick={() => setOpenDemo(false)}
                  size="small"
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Box>

              {/* Video Section */}
              <Box sx={{ p: 2 }}>
                <Box
                  sx={{
                    position: "relative",
                    paddingTop: "56.25%", // 16:9 Aspect Ratio
                    width: "100%",
                    bgcolor: "black",
                  }}
                >
                  <iframe
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    src="https://www.youtube.com/embed/m5y977NITwQ"
                    title="YouTube Demo"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Box>
              </Box>
              <Card sx={{ mx: 2, mb: 2 }}>
                <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={activeStep}
                  onChangeIndex={handleStepChange}
                  enableMouseEvents
                >
                  {tips.map((tip, index) => (
                    <div key={index}>
                      {Math.abs(activeStep - index) <= 2 ? (
                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            {tip.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {tip.content}
                          </Typography>
                        </CardContent>
                      ) : null}
                    </div>
                  ))}
                </SwipeableViews>
                <MobileStepper
                  steps={maxSteps}
                  position="static"
                  activeStep={activeStep}
                  nextButton={
                    <Button
                      size="small"
                      onClick={handleNext}
                      disabled={activeStep === maxSteps - 1}
                    >
                      Next
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </Button>
                  }
                  backButton={
                    <Button
                      size="small"
                      onClick={handleBack}
                      disabled={activeStep === 0}
                    >
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                      ) : (
                        <KeyboardArrowLeft />
                      )}
                      Back
                    </Button>
                  }
                />
              </Card>
            </Box>
          </Modal>
        </Stack>
      </Scrollbar>
    </Drawer>
  );
};

SideNav.propTypes = {
  color: PropTypes.oneOf(["blend-in", "discreet", "evident"]),
  sections: PropTypes.array,
};
