import PropTypes from "prop-types";
import { Box, Stack } from "@mui/material";
import { SideNavItem } from "./side-nav-item";

export const SideNavSection = (props) => {
  const { items = [], title, ...other } = props;

  return (
    <Stack
      component="ul"
      spacing={0.5}
      sx={{
        listStyle: "none",
        m: 0,
        p: 0,
      }}
      {...other}
    >
      {title && (
        <Box
          component="li"
          sx={{
            color: "var(--nav-section-title-color)",
            fontSize: 12,
            fontWeight: 700,
            lineHeight: 1.66,
            mb: 1,
            ml: 1,
            textTransform: "uppercase",
          }}
        >
          {title}
        </Box>
      )}
      {items.map((item) => (
        <SideNavItem key={item.title} {...item} />
      ))}
    </Stack>
  );
};

SideNavSection.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
};
