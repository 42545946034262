import { lazy, Suspense } from "react";
import { Layout } from "src/layouts/auth/auth-layout";
import { GuestGuard } from "src/guards/guest-guard";

const ForgotPasswordPage = lazy(() => import("src/pages/auth/forgot-password"));
const LoginPage = lazy(() => import("src/pages/auth/login"));
const RegisterPage = lazy(() => import("src/pages/auth/register"));
const ResetPasswordPage = lazy(() => import("src/pages/auth/reset-password"));
const VerifyCodePage = lazy(() => import("src/pages/auth/vertify-account"));

const Loader = () => <div>Loading...</div>;

export const authRoutes = [
  {
    children: [
      {
        path: "register",
        element: (
          <Suspense fallback={<Loader />}>
            <GuestGuard>
              <Layout>
                <RegisterPage />
              </Layout>
            </GuestGuard>
          </Suspense>
        ),
      },
      {
        path: "login",
        element: (
          <Suspense fallback={<Loader />}>
            <GuestGuard>
              <Layout>
                <LoginPage />
              </Layout>
            </GuestGuard>
          </Suspense>
        ),
      },
      {
        path: "verify-account",
        element: (
          <Suspense fallback={<Loader />}>
            <GuestGuard>
              <Layout>
                <VerifyCodePage />
              </Layout>
            </GuestGuard>
          </Suspense>
        ),
      },
      {
        path: "forgot-password",
        element: (
          <Suspense fallback={<Loader />}>
            <GuestGuard>
              <Layout>
                <ForgotPasswordPage />
              </Layout>
            </GuestGuard>
          </Suspense>
        ),
      },
      {
        path: "reset-password",
        element: (
          <Suspense fallback={<Loader />}>
            <GuestGuard>
              <Layout>
                <ResetPasswordPage />
              </Layout>
            </GuestGuard>
          </Suspense>
        ),
      },
    ],
  },
];
